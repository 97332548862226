<template>
	<div class="mt-50 size-px-16 full-height">
		<ul
			v-if="items.length > 0"
			class="pt-20"
		>
			<li
				v-for="(item, index) in items_list"
				:key="'item_' + index"
				class="mt-10 pa-10 flex-row justify-space-between"
				@click="onMore(item)"
			>
				<div>
					<div>{{ item.cartl_name }}</div>
					<div class="mt-10 color-gray size-px-12">{{ item.cartl_open_requestdate | transDate }}</div>
				</div>
				<div>
					<span class="label" :class="item.label">{{ item.cartl_open_request_state_code_name }}</span>
					<button
						class="ml-10"
						:disabled="item.cartl_open_request_state_code != 'CA02700003' && item.cartl_open_request_state_code != 'CA02700006'"
					></button>
				</div>
			</li>
		</ul>
		<div
			v-else
			class="flex-column justify-center text-center"
			style="height: 400px"
		>카르텔 개설 요청 내역이 없습니다.</div>

		<Popup_select
			v-if="item_more.cartl_open_request_number"
			@cancel="item_more = {}"

			class="z-index-layer"
		>
			<template
				v-slot:title
			>{{  item_more.cartl_name }}</template>
			<template
				v-slot:list
			>
				<li
					v-if="item_more.cartl_open_request_state_code == 'CA02700003'"
					class="cursor-pointer ptb-10"
					@click="onRequestCancel"
				>{{ $language.common.cancel_request }} <!-- 요청 취소 --></li>
				<li
					v-if="item_more.cartl_open_request_state_code == 'CA02700003'"
					class="cursor-pointer ptb-10"
					@click="toModify"
				>{{  $language.common.modify }} <!-- 수정 --></li>
				<li
					v-if="item_more.cartl_open_request_state_code == 'CA02700005'"
					class="ptb-10 text-center"
				>
					<div class="">
						<h4 class="">MAFIA 카르텔 개설 불가판정을 받았습니다.</h4>
					</div>
					<div class="mt-30 ">
						<h5 class="mt-30">개설 불가 사유</h5>
						<div class="mt-10 size-px-12 color-gray">{{ item_more.open_request_reply }}</div>
					</div>
					<div class="mt-50 flex-row justify-center">
						<button
							class="label label-primary mr-10 pa-10-20"
							@click="toModify"
						>재신청</button>
						<button
							class="label label-default pa-10-20"
							@click="item_more = {}"
						>확인</button>
					</div>
				</li>
				<li
					v-if="item_more.cartl_open_request_state_code == 'CA02700006'"
					class="cursor-pointer ptb-10"
					@click="toCartel"
				>이동</li>
			</template>
		</Popup_select>

		<Popup_confirm
			v-if="is_on_request_cancel"
			@click="postCancel"
			@cancel="is_on_request_cancel = false"
		>
			<template
				v-slot:title
			>카르텔 개설 요청 취소</template>
			<template
				v-slot:main-txt
			>해당 카르텔 개설 요청을 취소 하시겠습니까?</template>
			<template
				v-slot:sub-txt
			>개설 요청을 취소 하시려면 확인을 눌러주세요.</template>
		</Popup_confirm>
	</div>
</template>

<script>
import Popup_select from "@/view/Layout/PopupSelect";
import Popup_confirm from "@/view/Layout/PopupConfirm";
export default {
	name: 'CartelRequestList'
	,
	components: {Popup_confirm, Popup_select},
	props: ['user']
	, data: function(){
		return {
			program: {
				name: 'CartelRequestList'
				, title: this.$language.cartel.cartel_request_list
				, not_footer: true
				, type: 'mypage'
				, from: 'myPage'
				, is_save: true
				, callBack: {
					name: 'to_cartel_request'
				}
			}
			, items: []
			, item_last: { code: ''}
			, item_more: {}
			, items_request_status: [
				{ code: '', name: ''}
				, { code: '', name: ''}
			]
			, is_on_request_cancel: false
		}
	}
	, computed: {

		is_open_cartel: function(){
			let t = false
			if(this.item_last.code == 'Y'){
				t = true
			}
			return t
		}
		, items_list: function(){
			return this.items.filter((item) => {
				switch (item.cartl_open_request_state_code){
					case 'CA02700002':
						item.label = 'label-warning'
						break
					case 'CA02700003':
						item.label = 'label-progress'
						break
					case 'CA02700004':
						item.label = 'label-progress'
						break
					case 'CA02700005':
						item.label = 'label-danger'
						break
					case 'CA02700006':
						item.label = 'label-success'
						break
				}
				return item
			})
		}
	}
	, methods: {

		getData: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_request_list
					, data: {
						member_number: this.user.member_number
					}
					, type: true
				})

				if(result.success){
					this.items = result.data.cartl_open_request_list
					if(this.items.length > 0){
						this.item_last = this.items[this.items.length - 1]
					}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, postCancel: async function(){
			console.log('postCancel', this.item_more)
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.post_cartel_request_cancel
					, data: {
						member_number: this.user.member_number
						, cartl_open_request_number: this.item_more.cartl_open_request_number
					}
					, type: true
				})

				if(result.success){
					await this.getData()
					this.item_more = {}
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
				this.is_on_request_cancel = false
			}
		}
		, toCartelRequest: function(){
			this.$bus.$emit('to', { name: 'CartelRequest'})
		}
		, onMore: function(item){
			this.item_more = item
			this.getRequest()
		}
		, onRequestCancel: function(){
			this.is_on_request_cancel = true
		}
		, toModify: function(){
			this.$bus.$emit('to', { name: 'CartelRequestDetail', params: { idx: this.item_more.cartl_open_request_number}})
		}
		, toCartel: function(){
			this.$bus.$emit('to', { name: 'mafia049', params: { idx: this.item_more.cartl_number}})
		}
		, getRequest: async function(){
			try{
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_request
					, data: {
						member_number: this.user.member_number
						, cartl_open_request_number: this.item_more.cartl_open_request_number
					}
					, type: true
				})

				if(result.success){
					this.$set(this.item_more, 'open_request_reply', result.data.open_request_reply)
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
	}
	, created() {
		this.$emit('onLoad', this.program)
		this.getData()

		this.$bus.$off('callBack')
		this.$bus.$on('callBack', (call) => {
			if(call.name == 'to_cartel_request'){
				this.$emit('to', { name: 'CartelRequest'})
			}
		})

		document.body.scrollTop = 0
	}
}
</script>

<style>

	.label { display: inline-block !important; padding: 3px 10px; border-radius: 20px !important; font-size: 12px !important; letter-spacing: 1px !important; }
	.label-default { background-color: #bbb !important; color: white !important;}
	.label-progress { background-color: var(--blue01); color: white}
	.label-success { background-color: #1EA76E; color: white;}
	.label-warning { background-color: #f44336; color: white;}
	.label-danger { background-color: #FC4545; color: white !important;}
	.label-cancel { background-color: #f44336; color: white !important;}
	.label-notice { background-color: #DC505C; color: white}
	.label-primary { background-color: var(--blue01) !important; color: white !important;}
</style>